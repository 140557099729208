import { graphql, useStaticQuery } from "gatsby";

export const useImages = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulJobs {
        nodes {
          imageContainer {
            gatsbyImageData
          }
          imageHome {
            gatsbyImageData
          }
          title
        }
      }
    }
  `);

  return data.allContentfulJobs.nodes.map((i) => ({
    imagesArray: i.imageContainer,
    imagePrimary: i.imageHome.gatsbyImageData,
    idHome: i.imageHome.id,
    title: i.title,
  }));
};
