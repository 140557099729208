import React from "react";
import { Link } from "gatsby";

const Navbar = ({ gallery, setGallery }) => {
  return (
    <div className="flex  w-full top-0 left-0 right-0 bg-white z-40  mx-auto pb-4 lg:pb-8">
      <div className="flex justify-between items-center w-full ">
        <Link onClick={() => setGallery("Home")} to="/" className="text-2xl lg:text-3xl">
          Saltwood Homes
        </Link>
        <div className="font-body flex items-center ml-auto">
          <Link className="navbar" to="/contact">
            {gallery === "Home" ? "Contact" : null}
          </Link>
        </div>
        <div className="flex items-end">
          <p className="font-body navbar my-auto">
            {gallery === "Home" ? null : gallery}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
