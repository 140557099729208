import * as React from "react"

import Seo from "../components/seo"

import Container from "../components/Container"
import Layout from "../components/layout"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Layout>
       <Container />
    </Layout>
  </>
)

export default IndexPage
