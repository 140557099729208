import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import { useImages } from "../../utils/useProjects";
import { Link } from "gatsby";
import Navbar from "../Navbar";


const Container = () => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)
  const [gallery, setGallery] = useState("Home");

  const images = useImages();
  const arrayImages = images.filter((i) => i.title === gallery);

  // console.log(arrayImages)

  const ImagesHome = (data) => (
    <div className="columns-2 sm:columns-3">
      {images.map((i) => (
        <button
          key={i.idHome}
          onClick={() => setGallery(i.title)}
          className="mb-auto"
        >
          <GatsbyImage
            className=" mb-4 transform lg:hover:shadow-2xl w-full"
            image={i.imagePrimary}
            alt="project image"
          />
        </button>
      ))}
    </div>
  );

  const getImages = arrayImages[0];
  // console.log(getImages)

  const Image = () => (
    <>
      <div className="grid sm:grid-cols-2 gap-4 text-center">
        {getImages.imagesArray.map((i) => (
          <GatsbyImage
            key={i.id}
            image={i.gatsbyImageData}
            alt="image project"
            placeholder="tracedSVG"
            className=""
          />
        ))}
      </div>
      <div className="pt-6 lg:pt-8">

      <Link
        className="text-2xl "
        to="/"
        onClick={() => setGallery("Home")}
      >
        Back
      </Link>
      </div>
    </>
  );

  return (
    <div className="">
      <Navbar gallery={gallery} setGallery={setGallery} />
      {gallery === "Home" ? <ImagesHome /> : <Image />}
    </div>
  );
};

export default Container;
